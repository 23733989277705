import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import Login from './Login';

const Dashboard = () => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      const fetchData = async () => {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          'https://d3n69t2h7s04aj.cloudfront.net/dashboard/1', // Temp userId=1
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setData(response.data);
      };
      fetchData();
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) return <Login />;
  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h1>Welcome, {user?.email}</h1>
      <p>UNR50 Tokens: {data?.tokens || 'Loading...'}</p>
      <p>Dividends: ${data?.dividends || 'Loading...'}</p>
    </div>
  );
};

export default Dashboard;