import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Dashboard from './Dashboard';
import Callback from './Callback';

const Landing = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <div style={{
      fontFamily: 'Montserrat, sans-serif',
      backgroundColor: '#003087',
      color: '#ffffff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      textAlign: 'center'
    }}>
      <div style={{
        padding: '40px',
        background: 'rgba(255, 255, 255, 0.1)',
        borderRadius: '10px'
      }}>
        <h1 style={{ fontSize: '4em', fontWeight: 700, marginBottom: '20px' }}>HelioShare</h1>
        <p style={{ fontSize: '1.5em', marginBottom: '30px' }}>
          Track solar energy production and dividends for UNR’s 50 kW carport.
        </p>
        <button
          onClick={() => loginWithRedirect()}
          style={{
            padding: '15px 30px',
            backgroundColor: '#ffd700',
            color: '#003087',
            fontWeight: 700,
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer'
          }}
        >
          Explore Dashboard
        </button>
      </div>
    </div>
  );
};

const App = () => (
  <Router>
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/callback" element={<Callback />} />
      <Route path="/" element={<Landing />} />
    </Routes>
  </Router>
);

export default App;